import Vue from "vue";
import Vuex from "vuex";
import fetchClient from "../api/fetch";

Vue.use(Vuex);

const state = {
  fuelList: [],
};

const getters = {
    fuelList: (state) => state.fuelList,
};

const mutations = {
  setFuelList: (state, fuelList) => {
    state.fuelList = fuelList;
  },
};

const actions = {
  async loadFuelList({ commit }) {
    const response = await fetchClient().get(
      `/api/vehiclefuels?countryCodeId=AUS&languageId=ENG`
    );
    commit("setFuelList", response.data.data);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
