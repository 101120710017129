import Vue from "vue";
import Vuex from "vuex";
import body from "./body";
import brands from "./brands";
import doors from "./door";
import filters from "./filters"
import fuel from "./fuel";
import models from "./models";
import vehicles from "./vehicles";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    body,
    brands,
    doors,
    filters,
    fuel,
    models,
    vehicles,
  },
  state: {
    // state is the one who storing all the value that you get.
    selectedVehicleIds: [],
  },
  mutations: {
    // mutation is we're it mutates or updates the store property with the value provide.
    addSelectedVehicleId(state, id) {
      state.selectedVehicleIds.push(id);
    },
    removeSelectedVehicleId(state, id) {
      state.selectedVehicleIds = state.selectedVehicleIds.filter(
        (vehicleId) => vehicleId !== id
      );
    },
  },
  actions: {
    //actions are functions that call mutations
    toggleSelectedVehicleId({ commit, state }, vehicleId) {
      if (state.selectedVehicleIds.includes(vehicleId)) {
        commit("removeSelectedVehicleId", vehicleId);
      } else {
        commit("addSelectedVehicleId", vehicleId);
      }
    },
  },
  getters: {
    //Getters are how you bind store data to local data in your Vue components.
    selectedVehicleIds: (state) => state.selectedVehicleIds,
    areTwoOrMoreSelected: (state) => state.selectedVehicleIds.length >= 2,
  },
});
