import Vue from "vue";
import Vuex from "vuex";
import fetchClient from "../api/fetch";

Vue.use(Vuex);

const state = {
  bodyList: [],
};

const getters = {
  bodyList: (state) => state.bodyList,
};

const mutations = {
  setBodyList: (state, bodyList) => {
    state.bodyList = bodyList;
  },
};

const actions = {
  async loadBodyList({ commit }) {
    const response = await fetchClient().get(
      `/api/vehiclebodies?countryCodeId=AUS&languageId=ENG`
    );
    commit("setBodyList", response.data.data);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
