import Vue from "vue";
import Vuex from "vuex";
import fetchClient from "../api/fetch";

Vue.use(Vuex);

const state = {
  brands: [],
};

const getters = {
  brandsList: (state) => state.brands,
};

const mutations = {
  setBrands: (state, brands) => {
    state.brands = brands;
  },
};

const actions = {
  async loadBrandsList({ commit }) {
    const response = await fetchClient().get(
      "/api/brands?CountryCodeId=AUS&languageId=ENG"
    );
    commit("setBrands", response.data.data);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
