<template>
  <div class="paging-container">
    <div class="pb-3" id="pagination">
      <nav
        aria-label="Page navigation example"
        class="d-flex justify-content-center"
      >
        <ul
          class="pagination-bravoauto pagination justify-content-center d-flex col-11 flex-wrap"
        >
          <li class="page-item" v-for="pageNumber in pageData.totalPages">
            <a
              class="page-link"
              href="#"
              :class="{
                active:
                  pageData.totalPages === 1 ||
                  pageData.currentPage === pageNumber,
              }"
              @click="handlePageClick(pageNumber)"
            >
              {{ pageNumber }}
            </a>
          </li>
        </ul>
        <a
          class="sonar"
          :class="{ 'pulsating-shadow': isPulsating }"
          @mouseenter="startPulsating"
          v-if="areTwoOrMoreSelected"
          @click="handleButtonClick"
          onclick="location.href='https://bravoauto.com.au/vehicle-comparer/?vehicleId=2512859,2551150'"
          >Compare</a
        >
      </nav>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  name: "PaginationCompareBtn",

  methods: {
    ...mapActions(["loadFilterData"]),
    onLoadClicked: function (e) {
      if (this.pageData) {
        this.searhParams.pageNumber += 1;
        this.$refs.vehicleList.loadVehicleList(this.searhParams);
      }
    },
    startPulsating() {
      this.isPulsating = true;
    },
    stopPulsating() {
      this.isPulsating = false;
    },
    handleButtonClick() {
      // this function is if you click the compare button it will display the selected vehicleCodeId.
      // Access the selectedVehicleIds from the Vuex store and use it as needed
      const selectedIds = this.$store.getters["selectedVehicleIds"];
      console.log("Selected Vehicle IDs:", selectedIds);

      // Perform the action you want when the button is clicked
    },
    handlePageClick(pageNumber) {
      this.loadFilterData({ page: pageNumber });
    },
  },
  computed: {
    //calculating the SelectedVehicleIds along with the VehicleCodeId we're if reach 2 checks the button will appear.
    ...mapGetters(["areTwoOrMoreSelected", "pageData"]),
  },
  data() {
    return {
      isPulsating: true,
      searhParams: {
        pageNumber: 1,
        pageSize: 12,
        sortBy: "monthlypriceasc",
        selectedFacetsFilterData: [
          {
            field: "dealerships",
            items: ["bravoauto|"],
          },
          {
            field: "stockType",
            items: ["used"],
          },
          {
            field: "vehicleType",
            items: ["Car"],
          },
          {
            field: "isoffer",
            items: ["false"],
          },
        ],
      },
    };
  },
  created() {
    this.loadFilterData({ page: 1 });
  },
};
</script>

<style scoped>
.paging-container {
  padding: 10px;
  background-color: whitesmoke;
}

.sonar {
  width: 10em;
  height: 43px;
  padding: 0.4rem;
  border-radius: 2rem;
  background-color: #a7c712;
  border: 2px solid #a7c712;
  -webkit-animation: sonar 800ms ease-out infinite;
  animation: sonar 800ms ease-out infinite;
  animation: pulsate-shadow 2s infinite;
  text-decoration: none;
  display: inline-block;
  position: absolute;
  left: 87%;
  color: white;
  text-align: center;
  cursor: pointer;
}
@keyframes pulsate-shadow {
  0% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.4);
  }
  50% {
    box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.4);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.4);
  }
}
.pagination-bravoauto .page-link {
  border-radius: 24px !important;
  margin: 7px !important;
  color: #575756 !important;
  border: 1px solid #575756;
  height: 37px;
  width: 37px;
  padding: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-comparer {
  text-decoration: none;
  display: inline-block;
  color: white;
  text-align: center;
}
a {
  text-decoration: none;
  display: inline-block;
  color: white;
  text-align: center;
}
.page-link {
  position: relative;
  display: block;
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  font-size: var(--bs-pagination-font-size);
  color: #7fabec;
  text-decoration: none;
  background-color: var(--bs-pagination-bg);
  border: var(--bs-pagination-border-width) solid
    var(--bs-pagination-border-color);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.active > .page-link,
.page-link.active {
  z-index: 3;
  color: var(--cui-pagination-active-color);
  background-color: #8f84e9;
  border-color: var(--cui-pagination-active-border-color);
}
.pagination-bravoauto .active {
  background: #65a1d7;
  color: #ffffff !important;
  border: 1px solid #65a1d7;
}
.col-11 {
  flex: 0 0 auto;
  width: 87%;
}
</style>
