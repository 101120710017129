import Vue from "vue";
import Vuex from "vuex";
import fetchClient from "../api/fetch";

Vue.use(Vuex);

const state = {
  vehicles: [],
};

const getters = {
  listVehicles: (state) => state.vehicles,
};

const mutations = {
  setVehicles: (state, vehicles) => {
    state.vehicles = state.vehicles = vehicles;
  },
};

const actions = {
  async loadVehicleList({ commit }) {
    const response = await fetchClient().get(
      "/api/filters?countryCodeId=AUS&page=1&LanguageId=ENG"
    );

    commit("setVehicles", response.data.data);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
