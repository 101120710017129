<template>
  <div class="home">
    <Filters />
    <VehicleList ref="vehicleList" @getPageData="onGetPageData" />
    <PaginationCompareBtn />
  </div>
</template>

<script>
// @ is an alias to /src
import VehicleList from "@/components/VehicleList.vue";
import Filters from "@/components/Filters.vue";
import PaginationCompareBtn from "@/components/PaginationCompareBtn.vue";
export default {
  name: "HomeView",
  components: {
    VehicleList,
    Filters,
    PaginationCompareBtn,
  },
  methods: {
    onGetPageData(pageData) {
      this.pageData = pageData;
    },

    onLoadClicked: function (e) {
      if (this.pageData) {
        this.searhParams.pageNumber += 1;
        this.$refs.vehicleList.loadVehicleList(this.searhParams);
      }
    },
  },

  data() {
    return {
      pageData: {},
      searhParams: {
        pageNumber: 1,
        pageSize: 12,
        sortBy: "monthlypriceasc",
        selectedFacetsFilterData: [
          {
            field: "dealerships",
            items: ["bravoauto|"],
          },
          {
            field: "stockType",
            items: ["used"],
          },
          {
            field: "vehicleType",
            items: ["Car"],
          },
          {
            field: "isoffer",
            items: ["false"],
          },
        ],
      },
    };
  },
};
</script>
<style scoped>
.home {
  width: 100%;
  max-width: 1650px;
  min-height: 100vh;
  padding-right: 20px;
  padding-left: 20px;
  margin-right: auto;
  margin-left: auto;
}
</style>
