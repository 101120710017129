import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  // For Default
  {
    path: "/stock-locator/AUS",
    component: HomeView,
  },
  {
    path: "/stock-locator/COL",
    component: HomeView,
  },
  {
    path: "/stock-locator/FIN",
    component: HomeView,
  },
  {
    path: "/stock-locator/EST",
    component: HomeView,
  },
  {
    path: "/stock-locator/BEL",
    component: HomeView,
  },
  {
    path: "/stock-locator/HKG",
    component: HomeView,
  },
  {
    path: "/stock-locator/PHL",
    component: HomeView,
  },
  {
    path: "/stock-locator/LVA",
    component: HomeView,
  },
  // For English language
  {
    path: "/stock-locator/AUS/eng",
    component: HomeView,
  },
  {
    path: "/stock-locator/COL/eng",
    component: HomeView,
  },
  {
    path: "/stock-locator/FIN/eng",
    component: HomeView,
  },
  {
    path: "/stock-locator/EST/eng",
    component: HomeView,
  },
  {
    path: "/stock-locator/BEL/eng",
    component: HomeView,
  },
  {
    path: "/stock-locator/HKG/eng",
    component: HomeView,
  },
  {
    path: "/stock-locator/PHL/eng",
    component: HomeView,
  },
  {
    path: "/stock-locator/LVA/eng",
    component: HomeView,
  },
  // For Spanish language
  {
    path: "/stock-locator/AUS/spa",
    component: HomeView,
  },
  {
    path: "/stock-locator/COL/spa",
    component: HomeView,
  },
  {
    path: "/stock-locator/FIN/spa",
    component: HomeView,
  },
  {
    path: "/stock-locator/EST/spa",
    component: HomeView,
  },
  {
    path: "/stock-locator/BEL/spa",
    component: HomeView,
  },
  {
    path: "/stock-locator/HKG/spa",
    component: HomeView,
  },
  {
    path: "/stock-locator/PHL/spa",
    component: HomeView,
  },
  {
    path: "/stock-locator/LVA/spa",
    component: HomeView,
  },
  // For French language
  {
    path: "/stock-locator/AUS/fre",
    component: HomeView,
  },
  {
    path: "/stock-locator/COL/fre",
    component: HomeView,
  },
  {
    path: "/stock-locator/FIN/fre",
    component: HomeView,
  },
  {
    path: "/stock-locator/EST/fre",
    component: HomeView,
  },
  {
    path: "/stock-locator/BEL/fre",
    component: HomeView,
  },
  {
    path: "/stock-locator/HKG/fre",
    component: HomeView,
  },
  {
    path: "/stock-locator/PHL/fre",
    component: HomeView,
  },
  {
    path: "/stock-locator/LVA/fre",
    component: HomeView,
  },
  // For Dutch language
  {
    path: "/stock-locator/AUS/dut",
    component: HomeView,
  },
  {
    path: "/stock-locator/COL/dut",
    component: HomeView,
  },
  {
    path: "/stock-locator/FIN/dut",
    component: HomeView,
  },
  {
    path: "/stock-locator/EST/dut",
    component: HomeView,
  },
  {
    path: "/stock-locator/BEL/dut",
    component: HomeView,
  },
  {
    path: "/stock-locator/HKG/dut",
    component: HomeView,
  },
  {
    path: "/stock-locator/PHL/dut",
    component: HomeView,
  },
  {
    path: "/stock-locator/LVA/dut",
    component: HomeView,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
