import Vue from "vue";
import Vuex from "vuex";
import fetchClient from "../api/fetch";

Vue.use(Vuex);

const state = {
  pageData: {},
  filterData: {},
};

const getters = {
  pageData: (state) => state.pageData,
  filterData: (state) => state.filterData,
};

const mutations = {
  setPageData: (state, pageData) => (state.pageData = pageData),
  setFilterData: (state, filterData) => (state.filterData = filterData),
};

const actions = {
  async loadFilterData({ commit }, params) {
    let queryString = "";
    if (params)
      for (var key in params) {
        if (params[key] && queryString !== "") {
          queryString += "&";
        }
        if (params[key]) queryString += key + "=" + params[key];
      }

    const response = await fetchClient().get(
      "/api/filters" +
        "?countryCodeId=AUS&languageId=ENG" +
        `${queryString ? `&${queryString.toString()}` : ""}`
    );
    console.log(response);
    if (response.data !== "No Vehicle Data") {
      commit("setPageData", response.data.pageData);
      commit("setVehicles", response.data.vehicleData);
      console.log('went here')
    } else {
      console.log('went here')
      commit("setPageData", {});
      commit("setVehicles", {});
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
