import Vue from "vue";
import Vuex from "vuex";
import fetchClient from "../api/fetch";

Vue.use(Vuex);

const state = {
  doorsList: [],
};

const getters = {
  doorsList: (state) => state.doorsList,
};

const mutations = {
  setDoorsList: (state, doorsList) => {
    state.doorsList = doorsList;
  },
};

const actions = {
  async loadDoorsList({ commit }) {
    const response = await fetchClient().get(
      `/api/vehicledoors?countryCodeId=AUS&languageId=ENG`
    );
    commit("setDoorsList", response.data.data);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
