<template>
  <div>
    <div class="grid-container">
      <div>
        <label>Vehicle brand</label>
        <select
          name="car"
          class="car"
          id="brand"
          v-model="selectedBrand"
          @change="fetchModels"
        >
          <option value="Choose">Choose</option>
          <option
            v-for="brand in brandsList"
            :key="brand.brand"
            :value="brand.brand"
          >
            {{ brand.brand }}
          </option>
        </select>
      </div>

      <div>
        <label>Vehicle model</label>
        <select
          name="car"
          class="car"
          id="model"
          v-model="selectedModel"
          @change="setModel"
        >
          <option value="Choose">Choose</option>
          <option v-for="model in modelsList" :key="model.model">
            {{ model.model }}
          </option>
        </select>
      </div>

      <div>
        <label class="price">Price</label>
        <div>
          <input
            id="min_price"
            type="number"
            class="small-white-button"
            placeholder="From"
            min="1"
            max="100"
            v-model="fromPrice"
            @change="fetchFilteredVehicles"
          />
          <input
            id="min_price"
            type="number"
            class="small-white-button"
            placeholder="To"
            min="1"
            max="100"
            v-model="toPrice"
            @change="fetchFilteredVehicles"
          />
        </div>
      </div>

      <div>
        <label class="d-flex">Model Year</label>
        <div>
          <input
            id="start_year"
            type="number"
            class="small-white-button"
            placeholder="From"
            min="1"
            max="100"
            v-model="startYear"
            @change="fetchFilteredVehicles"
          />
          <input
            id="end_year"
            type="number"
            class="small-white-button"
            placeholder="To"
            min="1"
            max="100"
            v-model="endYear"
            @change="fetchFilteredVehicles"
          />
        </div>
      </div>

      <div>
        <label>Odometers</label>
        <div>
          <input
            id="start_odometer"
            type="number"
            class="small-white-button"
            placeholder="From"
            min="1"
            max="100"
            v-model="startOdometer"
            @change="fetchFilteredVehicles"
          />
          <input
            id="end_odometer"
            type="number"
            class="small-white-button"
            placeholder="To"
            min="1"
            max="100"
            v-model="endOdometer"
            @change="fetchFilteredVehicles"
          />
        </div>
      </div>

      <div>
        <label>Body</label>
        <select
          name="car"
          class="car"
          id="body"
          v-model="body"
          @change="fetchFilteredVehicles"
        >
          <option value="Choose">Choose</option>
          <option v-for="item in bodyList" :key="item.body">
            {{ item.body }}
          </option>
        </select>
      </div>

      <div>
        <label>Doors</label>
        <select
          name="car"
          class="car"
          id="doors"
          v-model="doors"
          @change="fetchFilteredVehicles"
        >
          <option value="Choose">Choose</option>
          <option v-for="item in doorsList" :key="item.door">
            {{ item.door }}
          </option>
        </select>
      </div>

      <div>
        <label>Fuel</label>
        <select
          name="car"
          class="car"
          id="fuel"
          v-model="fuel"
          @change="fetchFilteredVehicles"
        >
          <option value="Choose">Choose</option>
          <option v-for="item in fuelList" :key="item.fuel">
            {{ item.fuel }}
          </option>
        </select>
      </div>
      <div>
        <label> Engine Size</label>
        <div>
          <input
            id="min_price"
            type="number"
            class="small-white-button"
            placeholder="From"
            min="1"
            max="100"
            v-model="startEngineSize"
            @change="fetchFilteredVehicles"
          />
          <input
            id="min_price"
            type="number"
            class="small-white-button"
            placeholder="To"
            min="1"
            max="100"
            v-model="endEngineSize"
            @change="fetchFilteredVehicles"
          />
        </div>
      </div>

      <div>
        <label>Power (HP)</label>
        <div>
          <input
            id="min_price"
            type="number"
            class="small-white-button"
            placeholder="From"
            min="1"
            max="100"
          />
          <input
            id="min_price"
            type="number"
            class="small-white-button"
            placeholder="To"
            min="1"
            max="100"
          />
        </div>
      </div>

      <div>
        <label>Transmission</label>
        <select
          name="car"
          class="car"
          id="transmission"
          v-model="transmission"
          @change="fetchFilteredVehicles"
        >
          <option value="Choose">Choose</option>
          <option value="1 SP Constantly Variable Transmission">
            1 SP Constantly Variable Transmission
          </option>
          <option value="10 SP Constantly Variable Transmission">
            10 SP Constantly Variable Transmission
          </option>
          <option value="4 SP Automatic">4 SP Automatic</option>
          <option value="4 SP Sports Automatic">4 SP Sports Automatic</option>
          <option value="5 SP Automatic">5 SP Automatic</option>
          <option value="5 SP Manual">5 SP Manual</option>
          <option value="5 SP Sports Automatic">5 SP Sports Automatic</option>
          <option value="6 SP Automatic">6 SP Automatic</option>
          <option value="6 SP Constantly Variable Transmission">
            6 SP Constantly Variable Transmission
          </option>
          <option value="6 SP Manual">6 SP Manual</option>
          <option value="6 SP Sports Automatic">6 SP Sports Automatic</option>
          <option value="6 SP Sports Automatic Dual Clutch">
            6 SP Sports Automatic Dual Clutch
          </option>
          <option value="7 SP Constantly Variable Transmission">
            7 SP Constantly Variable Transmission
          </option>
          <option value="7 SP Sports Automatic">7 SP Sports Automatic</option>
          <option value="7 SP Sports Automatic Dual Clutch">
            7 SP Sports Automatic Dual Clutch
          </option>
          <option value="8 SP Automatic">8 SP Automatic</option>
          <option value="8 SP Constantly Variable Transmission">
            8 SP Constantly Variable Transmission
          </option>
          <option value="8 SP Sports Automatic">8 SP Sports Automatic</option>
          <option value="8 SP Sports Automatic Dual Clutch">
            8 SP Sports Automatic Dual Clutch
          </option>
          <option value="9 SP Sports Automatic">9 SP Sports Automatic</option>
        </select>
      </div>

      <div>
        <label>Weekly Repayment</label>
        <div>
          <input
            id="repayment_from"
            type="number"
            class="small-white-button"
            placeholder="From"
            min="1"
            max="100"
          />
          <input
            id="repayment_to"
            type="number"
            class="small-white-button"
            placeholder="To"
            min="1"
            max="100"
          />
        </div>
      </div>

      <div>
        <label>Location</label>
        <select name="car" class="car" id="city">
          <option value="">Choose</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
        </select>
      </div>

      <button class="large-green-button" @click="resetFilters">
        Reset parameters
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  name: "Filters",
  data() {
    return {
      selectedBrand: "Choose",
      selectedModel: "Choose",
      fromPrice: null,
      toPrice: null,
      startYear: null,
      endYear: null,
      startOdometer: null,
      endOdometer: null,
      body: "Choose",
      doors: "Choose",
      fuel: "Choose",
      startEngineSize: null,
      endEngineSize: null,
      startPower: null,
      endPower: null,
      transmission: "Choose",
      startWeeklyRepayment: null,
      endWeeklyRepayment: null,
      location: "Choose",
    };
  },

  methods: {
    ...mapActions([
      "loadBrandsList",
      "loadModelsList",
      "loadBodyList",
      "loadDoorsList",
      "loadFuelList",
      "loadFilterData",
      "loadVehicleList",
    ]),
    ...mapMutations(["setFilterData"]),
    fetchFilteredVehicles() {
      this.loadFilterData({
        brand: this.selectedBrand === "Choose" ? null : this.selectedBrand,
        model: this.selectedModel === "Choose" ? null : this.selectedModel,
        page: 1,
        startPrice: this.fromPrice,
        endPrice: this.toPrice,
        startYear: this.startYear,
        endYear: this.endYear,
        downPaymentPercent: null,
        startOdometer: this.startOdometer,
        endOdometer: this.endOdometer,
        body: this.body === "Choose" ? null : this.body,
        door: this.doors === "Choose" ? null : this.doors,
        fuel: this.fuel === "Choose" ? null : this.fuel,
        startEngineSize: this.startEngineSize,
        endEngineSize: this.endEngineSize,
        startPower: this.startPower,
        endPower: this.endPower,
        transmission: this.transmission === "Choose" ? null : this.transmission,
        startWeeklyRepayment: this.startWeeklyRepayment,
        endWeeklyRepayment: this.endWeeklyRepayment,
        location: this.location === "Choose" ? null : this.location,
      });
    },
    resetFilters() {
      // reset states
      this.selectedBrand = "Choose";
      this.selectedModel = "Choose";
      this.fromPrice = null;
      this.toPrice = null;
      this.startYear = null;
      this.endYear = null;
      this.startOdometer = null;
      this.endOdometer = null;
      this.body = "Choose";
      this.doors = "Choose";
      this.fuel = "Choose";
      this.startEngineSize = null;
      this.endEngineSize = null;
      this.startPower = null;
      this.endPower = null;
      this.transmission = "Choose";
      this.startWeeklyRepayment = null;
      this.endWeeklyRepayment = null;
      this.location = "Choose";

      // load all vehicles
      this.loadFilterData({ page: 1 });
    },
    setModel() {
      this.loadFilterData({
        brand: this.selectedBrand,
        page: 1,
        model: this.selectedModel,
        startPrice: this.fromPrice,
        endPrice: this.toPrice,
        startYear: this.startYear,
        endYear: this.endYear,
        downPaymentPercent: null,
        startOdometer: this.startOdometer,
        endOdometer: this.endOdometer,
        body: this.body === "Choose" ? null : this.body,
        door: this.doors === "Choose" ? null : this.doors,
        fuel: this.fuel === "Choose" ? null : this.fuel,
        startEngineSize: this.startEngineSize,
        endEngineSize: this.endEngineSize,
        startPower: this.startPower,
        endPower: this.endPower,
        transmission: this.transmission === "Choose" ? null : this.transmission,
        startWeeklyRepayment: this.startWeeklyRepayment,
        endWeeklyRepayment: this.endWeeklyRepayment,
        location: this.location === "Choose" ? null : this.location,
      });
    },
    fetchModels() {
      if (this.selectedBrand !== "Choose") {
        this.selectedModel = "Choose";
        this.loadModelsList(this.selectedBrand);
        this.loadFilterData({
          brand: this.selectedBrand,
          page: 1,
          model: null,
          startPrice: this.fromPrice,
          endPrice: this.toPrice,
          startYear: this.startYear,
          endYear: this.endYear,
          downPaymentPercent: null,
          startOdometer: this.startOdometer,
          endOdometer: this.endOdometer,
          body: this.body === "Choose" ? null : this.body,
          door: this.doors === "Choose" ? null : this.doors,
          fuel: this.fuel === "Choose" ? null : this.fuel,
          startEngineSize: this.startEngineSize,
          endEngineSize: this.endEngineSize,
          startPower: this.startPower,
          endPower: this.endPower,
          transmission:
            this.transmission === "Choose" ? null : this.transmission,
          startWeeklyRepayment: this.startWeeklyRepayment,
          endWeeklyRepayment: this.endWeeklyRepayment,
          location: this.location === "Choose" ? null : this.location,
        });
      }
    },
  },
  computed: {
    ...mapGetters([
      "brandsList",
      "modelsList",
      "bodyList",
      "doorsList",
      "fuelList",
      "filterData",
    ]),
  },
  created() {
    this.loadBrandsList();
    this.loadDoorsList();
    this.loadBodyList();
    this.loadFuelList();
  },
};
</script>

<style>
.home[data-v-9ea40744] {
  width: 100%;
  max-width: 1650px;
  min-height: 100vh;
  padding-right: 20px;
  padding-left: 20px;
  margin-right: auto;
  margin-left: auto;
  background: whitesmoke;
}
body {
  margin: 0;
  font-family: var(--cui-body-font-family);
  font-size: var(--cui-body-font-size);
  font-weight: var(--cui-body-font-weight);
  line-height: var(--cui-body-line-height);
  color: var(--cui-body-color);
  text-align: var(--cui-body-text-align);
  background-color: whitesmoke !important;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 21, 0);
}
.grid-container {
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  row-gap: 10px;
  margin: 10px;
  margin-right: auto;
  margin-top: 10px;
  align-items: end;
}

.grid-container ::placeholder {
  color: #65a1d7;
  opacity: 1;
}

.grid-container :-ms-input-placeholder {
  color: #65a1d7;
}

.grid-container ::-ms-input-placeholder {
  color: #65a1d7;
}

.b-form-spinbutton:not(.d-inline-flex):not(.flex-column) {
  align-items: center;
  width: 150px;
}
.btn-outline-success {
  --cui-btn-color: #070707;
  --cui-btn-border-color: #2eb85c;
  --cui-btn-hover-color: rgba(44, 56, 74, 0.95);
  --cui-btn-hover-bg: #58c67d;
  --cui-btn-hover-border-color: #43bf6c;
  --cui-btn-focus-shadow-rgb: 46, 184, 92;
  --cui-btn-active-color: rgba(44, 56, 74, 0.95);
  --cui-btn-active-bg: #58c67d;
  --cui-btn-active-border-color: #43bf6c;
  --cui-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 21, 0.125);
  --cui-btn-disabled-color: #2eb85c;
  --cui-btn-disabled-bg: transparent;
  --cui-gradient: none;
  width: 120px;
  height: 40px;
  text-align: center;
  align-content: baseline;
}
.small-white-button {
  margin-top: 10px;
  font-weight: 400;
  border-radius: 44px;
  font-size: 1.1vw;
  letter-spacing: 0;
  line-height: 24px;
  padding: 0px 20px;
  height: 5vh;
  text-align: left;
  background-color: #ffffff;
  color: #65a1d7;
  cursor: pointer;
  outline: none;
  border: none;
  border-color: #999999;
  margin-top: 10px;
  width: 7.1vw;
}
button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  width: 20px;
  height: 15px;
  /* display: flex; */
}
.grid-container .grid-item .row {
  display: grid;
  grid-template-columns: auto auto;
  font-size: 1.9vh;
  text-align: justify;
}
.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.large-green-button {
  font-weight: 400;
  border-radius: 50px;
  font-size: 1.1vw;
  letter-spacing: 0;
  line-height: 20px;
  padding: 0px 20px;
  height: 5vh;
  text-align: center;
  background-color: #f0f0ef;
  color: black;
  cursor: pointer;
  outline: none;
  border: 1px solid #a7c712;
  width: 16vw;
  margin-top: 10px;
}
.car {
  margin-top: 10px;
  font-weight: 400;
  border-radius: 50px;
  font-size: 1.1vw;
  letter-spacing: 0;
  line-height: 24px;
  padding: 0px 20px;
  height: 5vh;
  background-color: #ffffff;
  width: 16vw;
  border: #999999;
  color: #65a1d7;
  cursor: pointer;
  appearance: none;
  background-image: url(/src/assets/Images/arrow-down.png);
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 1em;
  border: none;
}
.price {
  text-align: start;
}
.grid-container label {
  display: block !important;
}
.grid-container > div > div {
  display: flex;
  gap: 10%;
}
@media (min-width: 769px) {
}
</style>
