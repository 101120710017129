import Vue from "vue";
import Vuex from "vuex";
import fetchClient from "../api/fetch";

Vue.use(Vuex);

const state = {
  models: [],
};

const getters = {
  modelsList: (state) => state.models,
};

const mutations = {
  setModels: (state, models) => {
    state.models = models;
  },
};

const actions = {
  async loadModelsList({ commit }, selectedBrand) {
    const response = await fetchClient().get(
      `/api/models?brand=${encodeURIComponent(
        selectedBrand
      )}&countryCodeId=AUS&languageId=ENG`
    );
    commit("setModels", response.data.data);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
